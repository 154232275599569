import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history", //removes the hashtag in the url
  routes: [
    {
      path: "/auth",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("@/view/pages/auth/Login")
        }
      ]
    },
    {
      path: "/",
      redirect: "/contribute",
      component: () => import("@/view/layout/DonationLayout"),
      children: [
        {
          path: "/contribute",
          name: "contribute",
          component: () => import("@/view/pages/contribute/Contribute")
        },
        {
          path: "/response-handler",
          name: "response-handler",
          component: () => import("@/view/pages/contribute/ResponseHandler")
        },
        {
          path: "/ThankYou",
          name: "ThankYou",
          component: () => import("@/view/pages/contribute/ThankYou")
        },
        {
          path: "/success-page",
          name: "success-page",
          component: () => import("@/view/pages/contribute/SuccessPage")
        }
      ]
    },
    {
      path: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/donations",
          name: "donations",
          component: () => import("@/view/pages/Donations.vue")
        },
        {
          path: "/donationView/:donationId",
          name: "donationView",
          component: () => import("@/view/pages/admin/DonationView.vue")
        }
      ]
    },

    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
